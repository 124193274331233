html, body, #root {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


}

main {
  flex: 1 0 auto;
}

.App {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.bottom-nav {
  margin-top: 40px;
}

.input-field.col label {
  left: -0.25rem;
  text-overflow: ellipsis;
  width: 110%;
}

.checkform label {
  display: block;
  clear: both;
  width: 300px;
}

.pdfStyle {
  padding: 50px
}

/*Footer*/

.lower-footer {
  width: 100%;
  background-color: #333465;
  color: white;
  flex-shrink: 0;
  min-height: 60px;
  padding-top: 20px;
}


img.cfLogo {
  max-width: 210px;
}

.footer-logout {
  background: none;
  cursor: pointer;
  padding-right: 45px;
  color: #d9d9e1;
}

/*Form*/

.form-radio-bottom {
  margin-bottom: 15px
}

.custom-form-input {
  margin-bottom: 0px;
}

.input-field.col label {
    white-space: nowrap;
    overflow: hidden;
}

.form-textarea {
  border: solid 2px #ccc;
  border-radius: 8px;
  padding: 7px 15px;
}

.form-container {
  margin-top: 25px;
}

.edit-form {
  color: #333465;
  
}

.edit-form-button {
  margin-top: 40px;
}

.content {
  overflow: auto;
  flex-grow: 1;
}

input[type=date]::-webkit-inner-spin-button,input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  display: none;
}

    
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mainLogo {
  max-width: 400px;
  width: 100%;
}

.nav-bar-custom {
  margin-top: 65px;
  box-shadow: none;
}

.input-custom {
  border: 2px solid rgba(22, 23, 12, 0.28);
}

.welcome-title {
  margin-bottom: 50px;
  color: rgb(99, 99, 99);
  text-transform: capitalize;
}

.success-title {
  color: #9c2030;
  margin-top: 100px;
}

.button-submit {
  padding: 15px 52px;
  height: 50px;
  line-height: inherit;
  border-radius: 9px;
  background: #9c2030;
  color: #fff;
  display: block;
  margin: auto;
  
}

.success-button {
  max-width: 250px;
}

.button-submit:hover {
  background-color: #333465;
  color: #fff;
}

.form-error {
  color: #a50b21b8;
  font-style: italic;
}

.custom-input {
  border: 2px solid #808080a6;
  border-radius: 10px;
  padding: 3px 22px;
  margin-bottom: 25px;

}

.custom-input>label {
  padding-left: 17px;
  padding-top: 3px;
}

.custom-input input {
  border-bottom: none !important;
  margin-bottom: 0px !important;
}

.custom-input input::placeholder { /* WebKit, Blink, Edge */
    color:    #444;
}



[type="radio"]:checked+span:after, [type="radio"].with-gap:checked+span:after {
  background-color: #a50b21;
}

[type="radio"]:checked+span:after, [type="radio"].with-gap:checked+span:before, [type="radio"].with-gap:checked+span:after {
  border: 2px solid #a50b21;
}

.link {
  color: #333465;
  text-decoration: underline;
}

.your-progress {
  font-size: 7pt;
  color: rgb(175, 174, 174);
  margin-bottom: 0px;
}

.percent-complete {
  font-size: 12pt;
  margin: 7px 0px;
  color: #808080;
}

.loginout-nav {
  margin-bottom: 85px;
}


.back-button {
  background-color: #fff;
  color: #a50b21;
}

.mailing-same {
  margin-top: 40px;
}

.form-block-fix {
  min-height: 80px;
}

.btn:focus, .btn-large:focus, .btn-small:focus {
  background-color: #333465;
  color: #fff;
}

ul:not(.browser-default)>li {
  list-style-type: circle;
}

input#mailingState {
  text-transform: uppercase;
}

input#physicalState {
  text-transform: uppercase;
}

input#stateFormation {
  text-transform: uppercase;
}

/* Dashboard Form View */
td.dashboardText {
  text-transform: capitalize;
}

p.form-view-label span {
  font-weight: 600;
  font-size: 14pt;
  text-transform: capitalize;
  color: #545454;
  background: #efefef;
  padding: 6px;
}

img.mainLogo.formLogo {
  margin: 5px auto 25px auto;
  float: none !important;
  display: block;
  border-bottom: 2px solid #9c9c9c;
  padding-bottom: 10px;
}

p.form-view-label {
  font-family: "Helvetica", "open sans", "Roboto";
  font-size: 13pt;
  padding: 5px;
}

a.form-backto {
  font-size: 15pt;
  color: #6882ab;
}

.dashboardLink:visited {
  color: #95c8e2;
}

@media only screen and (max-width: 600px) {
  .mainLogo {
      padding-left: 0;
      width: 100%;
  }

  .progress-custom {
    padding-right: 20% !important;
  }

  .form-container {
    margin-top: 0px;
  }

  .row.center.form {
    margin-bottom: 60px;
  }

  .welcome-title {
    font-size: 9vw;
  }

  .button-submit {
    width: 100%;
    margin-top: 10px;
  }

  .success-title {
    font-size: 9vw;
  }
  
  .button-submit {
    padding: 15px 10px;
  }
}